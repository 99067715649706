import * as React from "react"

const OtherPage = () => {
  return (
    <main>
      <h1>Other Page</h1>
    </main>
  )
}

export default OtherPage
